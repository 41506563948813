<template>
    <div class="LogBox">
        <br><br><br><br>

        v3.3.0 （2024-9-30更新）
        <br><br>
        <div class="conguatulations"><span class="notice">大事件</span></div>换用vue<br>
        <span class="delete"><span style="color: white">删除</span></span>删除旧网页的内容<br>
        持续更新中……
        <br><br><br><br>
        v3.3 beta（2024-7更新【未正式推送】）
        <br><br>
        <div class="conguatulations"><span class="notice">大事件</span></div>网站域名更换<br>
            <span class="delete"><span style="color: white">删除</span></span>删除了wap页面
        <br><br><br><br>
        v3.2（2023-10-5更新）
        <br><br>
        <div class="repair"><span class="notice">tips</span></div>预计将在2023-10-6日提交更新
              <div class="rebuild"><span class="notice">修改</span></div>添加手机版<br>
            <!-- <div class="rebuild"><span class="notice">修改</span></div>字体修改为 江城圆体700W<br> -->
            <!-- <div class="rebuild"><span class="notice">新增</span></div>添加一言<br> -->
            <span class="delete"><span style="color: white">重要</span></span><br>搜索引擎保存存在bug
        <br><br><br><br>
        v3.1（2023-8-21更新）
        <br><br>
        <div class="rebuild"><span class="notice">新增</span></div>添加一言<br>
            <span class="delete"><span style="color: white">重要</span></span><br>设置存在bug
        <br><br><br><br>
        v3.0
        <br><br>
        <div class="conguatulations"><span class="notice">大事件</span></div>更名为 LiteO2 Tab
        <div class="repair"><span class="notice">修复</span></div>将定位模式改为相对定位
        <div class="rebuild"><span class="notice">新增</span></div>添加Layui引用<br>
        <span class="delete"><span style="color: white">删除</span></span><br>移除时间效果及在线北京时间
        <div class="rebuild"><span class="notice">新增</span></div>添加设置，可以修改背景图片及搜索引擎<br>
        <br><br><br><br>
        2022-4-17
        <br><br>
        <div class="new"><span class="notice">新增</span></div>360搜索切换选项
        <div class="repair"><span class="notice">修复</span></div>修复了底部信息显示换行的bug
        <br><br><br><br>
        2022-1-12
        <br><br>
        <div class="rebuild"><span class="notice">新增</span></div>解决了在选择搜索引擎时点击背景框不能成功选中的问题
        <br><br><br><br>
        2021-11-7
        <br><br>
        <div class="rebuild"><span class="notice">新增</span></div>添加“在线北京时间”到时间中
        <div class="rebuild"><span class="notice">新增</span></div>将“关于”关闭按钮写入popLayer层
        <div class="rebuild"><span class="notice">新增</span></div>默认搜索引擎设定为百度
        <div class="rebuild"><span class="notice">新增</span></div>修复搜索引擎选定提示错误
        <div class="repair"><span class="notice">修复</span></div><span class="delete"><span
                style="color: white">重要</span></span>&nbsp;&nbsp;已知存在的搜索引擎选择bug
        <div class="conguatulations"><span class="notice">大事件</span></div>&nbsp;&nbsp;更新为2.0版本
        <br><br><br><br>
        2021-8-17
        <br><br>
        <div class="repair"><span class="notice">修复</span></div><span class="delete"><span
                style="color: white">重要</span></span>&nbsp;&nbsp;已知存在的搜索引擎选择bug
        <div class="rebuild"><span class="notice">新增</span></div>添加搜索引擎选定提示
        <div class="new"><span class="notice">新增</span></div>搜索引擎选择
        <div class="rebuild"><span class="notice">新增</span></div>UI新增
        <div class="repair"><span class="notice">修复</span></div><span class="delete"><span
                style="color: white">重要</span></span>&nbsp;&nbsp;搜索框placeholder随动画移动错位问题
        <br><br><br><br>
        2021-8-16
        <br><br>
        <div class="repair"><span class="notice">修复</span></div><span class="delete"><span
                style="color: white">重要</span></span>&nbsp;&nbsp;搜索框鬼畜问题
        <div class="repair"><span class="notice">修复</span></div>时间动画错误问题
        <div class="new"><span class="notice">新增</span></div>今日古诗随机古诗显示
        <div class="rebuild"><span class="notice">新增</span></div>修改背景为必应每日壁纸
        <div class="conguatulations"><span class="notice">大事件</span></div>本页代码重写

        <br><br><br><br>
        2021-8-16 16:10
        <br><br>
        <div class="conguatulations"><span class="notice">大事件</span></div>开始制作

    </div>

</template>

<script>
export default {
    name: 'NoticeBox'
}
</script>

<style scoped>
.LogBox {
    box-sizing: border-box;
    overflow-y: scroll;
}
</style>